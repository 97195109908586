export const loginOptions = {
  url: process.env.ADFS_URL || "",
  endpoint: process.env.ADFS_ENDPOINT || "",
  tenant: process.env.ADFS_TENANT || "",
  client_id: process.env.ADFS_CLIENT_ID || "",
  response_type: "code",
  response_mode: "query",
  redirect_uri: process.env.ADFS_REDIRECT_URI || "",
  state: {},
  scope: "", // Scopes are defined below and parsed in `index.ts`
};

export const scopes = [
  "https://graph.microsoft.com/user.read",
  "offline_access",
];

export const adminScopes = [
  "https://graph.microsoft.com/user.read",
  "https://graph.microsoft.com/user.readbasic.all",
  "https://graph.microsoft.com/mail.send",
  "offline_access",
];

export const LOCAL_STORAGE_PREFIX = "sparkthink-auth";
