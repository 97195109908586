// Types
export interface LoginOptions {
  url: string;
  endpoint: string;
  tenant: string;
  client_id: string;
  response_type: string;
  response_mode: string;
  redirect_uri: string;
  state: any;
  scope: string;
  storageKey?: string;
  storageType?: "session" | "local";
}

export interface IUser {
  token: string;
  aud: string;
  exp: number;
  roles: string;
  emailaddress: string;
  givenname: string;
  nameidentifier: string;
  surname: string;
  iss: string;
}

const buildPath = (options: LoginOptions) => {
  // convert state object to base64 string
  let stateString = "";
  try {
    const stateJson = JSON.stringify(options.state);
    const stateBase64 = window.btoa(stateJson);
    stateString = encodeURIComponent(stateBase64);
  } catch (ex) {
    console.error("Error converting state to base64 string.", ex);
  }

  return `${options.url + options.tenant + options.endpoint}?client_id=${
    options.client_id
  }&response_type=${options.response_type}&redirect_uri=${
    options.redirect_uri
  }&response_mode=${options.response_mode}&scope=${
    options.scope
  }&state=${stateString}`;
};

export const AuthService = {
  buildPath,
};

export default AuthService;
