// Imports

// Utils
import { v4 } from "uuid";
// Services
import { AuthService, LoginOptions } from "./lib/AuthService";
// Configuration
import {
  adminScopes,
  LOCAL_STORAGE_PREFIX,
  loginOptions,
  scopes,
} from "./config";
// Sentry
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

// Initialize Sentry error tracking
const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";
if (!isLocalhost) {
  Sentry.init({
    dsn: "https://844ea552d85f457187f4669daa9c1faa@o313153.ingest.sentry.io/2531465",
    environment: process.env.SPARKTHINK_ENV,
    // TO DO: remove release line below once integrated into pipeline:
    release: "spark-auth@1.13.0",
    integrations: [new BrowserTracing()],
    // Trace sample rate of 1.0 is capturing 100% of transactions for performance monitoring
    // Sentry recommends ~0.2 in Prod environments
    tracesSampleRate: process.env.SPARKTHINK_ENV === "production" ? 0.2 : 1.0,
  });
}

declare global {
  interface Window {
    authenticate: (token: string, returnedSecurityToken: string) => void;
  }
}

function getUrlVars() {
  let vars: { [key: string]: string } = {};
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (substring, key, value) {
      vars[key] = value;
      return substring;
    },
  );
  return vars;
}

const init = () => {
  // Get url variables
  const variables: {
    auth?: string;
    referrer?: string;
    accessCode?: string;
    collectorId?: string;
  } = getUrlVars();

  const securityToken = v4();
  const loginMode =
    variables.accessCode &&
    variables.accessCode.length > 0 &&
    variables.referrer &&
    (variables.referrer.indexOf("/vote") > -1 ||
      variables.referrer.indexOf("/live") > -1 ||
      variables.referrer.indexOf("/fill/assessment") > -1)
      ? "participant"
      : "admin";

  const useScopes = loginMode === "admin" ? adminScopes : scopes;

  const formattedLoginOptions: LoginOptions = {
    ...loginOptions,
    state: { securityToken, loginMode, accessCode: variables.accessCode },
    scope: encodeURIComponent(useScopes.join(" ")),
  };

  // Update screen text based on referrer
  const loginButton = document.getElementById("loginBtn");
  const loginCopy = document.getElementById("loginCopy");
  if (
    variables.referrer &&
    variables.referrer.indexOf("/vote/") > -1 &&
    loginButton &&
    loginCopy
  ) {
    loginButton.innerText = "Log in and take survey";
    loginCopy.innerText =
      "The creator of this survey is requiring respondents to log in with their Slalom account before answering. Once you log in, you'll be taken to the survey and your answers will be saved.";
  }
  if (
    variables.referrer &&
    variables.referrer.indexOf("/fill/assessment/") > -1 &&
    loginButton &&
    loginCopy
  ) {
    loginButton.innerText = "Log in and take assessment";
    loginCopy.innerText =
      "The creator of this assessment is requiring respondents to log in with their Slalom account before answering. Once you log in, you'll be taken to the assessment and your answers will be saved.";
  }
  if (
    variables.referrer &&
    variables.referrer.indexOf("/live/") > -1 &&
    loginButton &&
    loginCopy
  ) {
    loginButton.innerText = "Log in to workshop";
    loginCopy.innerText =
      "To access this workshop you will first need to login with your Slalom account. Once you login you’ll be taken to the workshop in progress.";
  }

  const ssoLogin = () => {
    // Get login path from auth class
    const path = AuthService.buildPath(formattedLoginOptions);
    // Set referrer in local storage
    window.localStorage.setItem(
      `${LOCAL_STORAGE_PREFIX}-referrer`,
      variables.referrer ?? "https://sparkthink.slalom.com/admin",
    );

    window.localStorage.setItem(
      `${LOCAL_STORAGE_PREFIX}-security-token`,
      securityToken,
    );

    if (variables.auth) {
      window.localStorage.setItem(
        `${LOCAL_STORAGE_PREFIX}-referrer-auth`,
        variables.auth,
      );
    }

    if (variables.collectorId) {
      window.localStorage.setItem(
        `${LOCAL_STORAGE_PREFIX}-referrer-collectorId`,
        variables.collectorId,
      );
    }
    // redirect page to auth
    window.location.href = path;
  };

  if (!loginButton) {
    Sentry.captureMessage(
      "Unable to locate login button. Login page logic cannot be initialized.",
    );
  }

  loginButton?.addEventListener("click", ssoLogin);
};

init();
